import ComponentHeader from './Components/Header';


interface PrivateLayoutProps {
  children: React.ReactNode;
};

export default function PrivateLayout({children}: PrivateLayoutProps) {
  return (
    <>
      <ComponentHeader />
      <main>{children}</main>
    </>
  )
}