import { useState } from 'react';
import { List, ListItemButton, ListItemText, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';

export default function MenuList() {
  const [openMenus, setOpenMenus] = useState({
    userMenu: false,
    companyMenu: false,
  });

  const handleCollapseClick = (menu: 'userMenu' | 'companyMenu') => {
    setOpenMenus((prev) => ({
      ...prev,
      [menu]: !prev[menu],
    }));
  };

  return (
    <List
      component='nav'
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
      }}
    >
      <ListItemButton component={Link} to="/dashboard">
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton onClick={() => handleCollapseClick('userMenu')}>
        <ListItemText primary="Usuário" />
        {openMenus.userMenu ? <ExpandLess color='primary' /> : <ExpandMore color='primary' />}
      </ListItemButton>
      <Collapse in={openMenus.userMenu}>
        <List component='div' disablePadding>
          <ListItemButton component={Link} to="#" sx={{ pl: 4 }}>
            <ListItemText primary="Editar meu usuário" />
          </ListItemButton>
          <ListItemButton component={Link} to="#" sx={{ pl: 4 }}>
            <ListItemText primary="Adicionar Usuário" />
          </ListItemButton>
          <ListItemButton component={Link} to="#" sx={{ pl: 4 }}>
            <ListItemText primary="Gerenciar Usuário" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton onClick={() => handleCollapseClick('companyMenu')}>
        <ListItemText primary="Empresa" />
        {openMenus.companyMenu ? <ExpandLess color='primary' /> : <ExpandMore color='primary' />}
      </ListItemButton>
      <Collapse in={openMenus.companyMenu}>
        <List component='div' disablePadding>
          <ListItemButton component={Link} to="#" sx={{ pl: 4 }}>
            <ListItemText primary="Informações" />
          </ListItemButton>
          <ListItemButton component={Link} to="#" sx={{ pl: 4 }}>
            <ListItemText primary="Editar informações" />
          </ListItemButton>
        </List>
      </Collapse>

    </List>
  )
}