import styled from 'styled-components';


// Styled Components
export const VehiclesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  background-color: #f9f9f9;
`;

export const Title = styled.h1`
  font-size: 2rem;
  font-family: var(--font-Roboto);
  color: #0286ea;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
`;

export const TableWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th, td {
    padding: 15px;
    text-align: left;
    border: 1px solid #ddd;
  }

  th {
    background-color: #f4f4f4;
    color: #0286ea;
    font-family: var(--font-Roboto);
  }

  td {
    font-family: var(--font-Roboto);
    color: #333;
  }

  tbody tr:hover {
    background-color: #f9f9f9;
  }
`;

export const ActionButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #0286ea;
  font-size: 16px;
  margin-right: 10px;

  &:hover {
    color: #155a8a;
  }

  &:last-child {
    margin-right: 0;
  }
`;
