import { useContext, useState } from 'react';
import { AuthContext } from '../../../../Context/Auth/AuthContext';
import { Formik, Form } from 'formik';
import { loginSchema } from '../../schemas/loginSchema';
import { useNavigate } from 'react-router-dom';

import { MdErrorOutline } from "react-icons/md";
import { LiaUserLockSolid } from "react-icons/lia";

import { Container, CustomLink } from './styles/SignIn.styles';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

import {
  Box,
  Button,
  TextField,
  Typography,
  Card,
  CircularProgress,
  IconButton, 
  InputAdornment,
} from '@mui/material';

import MaskCPF from '../../../../Shared/Functions/Mask/MaskCPF';
import { LogoAvaVehicles } from '../../../../Components/CustomIcons/SvgAvaVeiculos';


const SingInCard = () => {
  const { signIn } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  function removeMask(cpf: string) {
    return cpf.replace(/[^\d]/g, '');
  }

  async function handleSignIn(
    values: { cpf: string; password: string },
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldError: (field: string, message: string) => void
  ) {
    try {
      const cleanedCpf = removeMask(values.cpf);
      await signIn({ cpf: cleanedCpf, password: values.password });
      navigate('/');
       /*gambiarra*/ 
      window.location.reload();
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage('Erro desconhecido.');
      }
    } finally {
      setSubmitting(false);
    }
  };

  function clearError() {
    setErrorMessage('');
  }

  return (
    <Container>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 4,
          boxShadow: 3,
        }}
      >
        <Box sx={{ p: 1, mb: 2 }}>
          <LogoAvaVehicles />
        </Box>
        <Typography component="h1" variant="h5" color='#00AFEF'>
          Bem vindo ao Sistema AVA Veículos
        </Typography>
        <Formik
          initialValues={{ cpf: '', password: '' }}
          validationSchema={loginSchema}
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            setSubmitting(true);
            handleSignIn(values, setSubmitting, setFieldError);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
            <Form>
              <Box sx={{ mt: 3 }}>
                <TextField
                  fullWidth
                  id="cpf"
                  name="cpf"
                  label="Login"
                  placeholder="Digite seu cpf"
                  value={MaskCPF(values.cpf)}
                  onChange={(e) => {
                    handleChange(e);
                    clearError();
                  }}
                  onBlur={handleBlur}
                  error={touched.cpf && Boolean(errors.cpf)}
                  helperText={touched.cpf && errors.cpf}
                  margin="normal"
                />

                <TextField
                  fullWidth
                  id="password"
                  name="password"
                  label="Sua Senha"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Sua Senha"
                  value={values.password}
                  onChange={(e) => {
                    handleChange(e);
                    clearError();
                  }}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  margin="normal"
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                {errorMessage && (
                  <Typography color="error" sx={{ mt: 1, display: "flex", alignItems: "center" }}>
                    <MdErrorOutline style={{ marginRight: 8 }} />
                    {errorMessage}
                  </Typography>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color='primary'
                  sx={{ mt: 3, mb: 2 }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <CircularProgress size={24} /> : 'Entrar'}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
        <Typography textAlign='center'>
          <CustomLink to='/resetLogin'>
            <LiaUserLockSolid size={22}/>
            Esqueci minha senha
          </CustomLink>
        </Typography>
      </Card>
    </Container >
  );
};

export default SingInCard;