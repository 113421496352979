import * as React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface CurrencyMaskProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
}

const CurrencyMaskCustom = React.forwardRef<NumericFormatProps, CurrencyMaskProps>(
  function CurrencyMaskCustom(props, ref) {
    const { onChange, name, value, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator="."
        decimalSeparator=","
        prefix="R$"
        decimalScale={2}
        fixedDecimalScale={true}
        valueIsNumericString
      />
    );
  },
);

export default CurrencyMaskCustom;
