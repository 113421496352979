import { FaChartLine, FaTachometerAlt, FaUserCheck } from "react-icons/fa";
import { GrDocumentPerformance } from "react-icons/gr";

import { Stack, Typography, Box } from '@mui/material'
import { LogoAvaVehicles } from '../../../../Components/CustomIcons/SvgAvaVeiculos';

const items = [
  {
    icon: <FaChartLine size={56}/>,
    title: 'Com uma gestão melhorada',
    description: 'Com uma gestão melhorada, você alcança maior eficiência, controle total sobre seu negócio e resultados expressivos em vendas e crescimento.'
  },
  {
    icon: <GrDocumentPerformance size={42}/>,
    title: 'Controle de vendas',
    description: 'Maximize seus lucros, organize suas transações e tome decisões estratégicas para o crescimento do seu negócio.'
  },
  {
    icon: <FaUserCheck size={36}/>,
    title: 'Gestão de leads',
    description: 'Capture oportunidades, acompanhe o progresso e transforme potenciais clientes em vendas reais.'
  },
  {
    icon: <FaTachometerAlt size={36}/>,
    title: 'Performace',
    description: 'Otimize seus processos, aumente sua produtividade e alcance resultados extraordinários'
  },
];

export default function Content() {
  return(
    <Stack
    sx={{ flexDirection: 'column', alignSelf: 'center', gap: 4, maxWidth: 450 }}
  >
    <Box sx={{ 
      display: { xs: 'none', md: 'flex' },
      }}
    >
      <LogoAvaVehicles/>
    </Box>
    {items.map((item, index) => (
      <Stack key={index} direction="row" sx={{ gap: 2 }}>
        {item.icon}
        <div>
          <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
            {item.title}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {item.description}
          </Typography>
        </div>
      </Stack>
    ))}
  </Stack>
  )
};