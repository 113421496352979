import { createGlobalStyle } from "styled-components";

export const Colors = createGlobalStyle`
  :root {
    --color-BluePrimary: #00AFEF;
    --color-BackgroundAFWhite: #f1f3f5;
    --color-GreyText: #95a5a6;
    --color-ColorLabel: #91d5ee;
    --color-TextBlack: #000;
    --color-TextGrey: #c8caca;
  }
`