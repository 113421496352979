import styled from 'styled-components';

// Container principal da lista de empresas
export const CompaniesContainer = styled.div`
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// Título estilizado com a fonte Roboto
export const Title = styled.h1`
  font-family: var(--font-Roboto);
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
`;

// Tabela estilizada
export const CompaniesTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th {
    background-color: #f4f4f4;
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
  }
`;

// Linha da tabela, com alternância de cor
interface TableRowProps {
  isEven: boolean;
}

export const TableRow = styled.tr<TableRowProps>`
  background-color: ${(props) => (props.isEven ? '#f9f9f9' : '#fff')};

  &:hover {
    background-color: #e6f7ff;
  }
`;

// Botão para ver detalhes
export const DetailButton = styled.button`
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

// Botão para voltar ao dashboard
export const BackButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #218838;
  }
`;
