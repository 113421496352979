import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';

import { useContext, useState } from 'react';
import { MenuUserData } from './Data/MenuUserData';
import { AuthContext } from '../../../../../../Context/Auth/AuthContext';
import AvatarComponent from '../../../../../../../src/Components/AvatarComponent/AvatarComponent';

export default function MenuUser() {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const { signOut } = useContext(AuthContext);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    signOut();
    window.location.reload();
  }

  return (
    <Box sx={{ flexGrow: 0, ml: 1.5, }}>
      <Tooltip title='Informações'>
        <IconButton onClick={handleOpenUserMenu}>
          <AvatarComponent />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {MenuUserData[0].userOption.map((option, index) => (
          <MenuItem
            key={index}
            onClick={option.label === 'Sair' ? handleLogout : handleCloseUserMenu}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: "center",
              fontSize: '0.9em',
              fontWeight: 400,
              color: 'var(--color-GreyText)',
              '&:hover': {
                color: 'var(--color-BluePrimary)',
                fontWeight: 'bold',
              },
            }}
          >
            {option.icon}
            <Typography>{option.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};