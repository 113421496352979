import { useState, useRef } from 'react';

import { 
  ContainerThumbnail, 
  Thumbnail, 
  ThumbnailContainer, 
  ContainerImgViewer, 
  MainImageContainer,
} from './styles/ImageViewer.styles';
import { BsArrowDownCircleFill, BsArrowUpCircleFill } from "react-icons/bs";

export interface Image {
  id: number; // ou outro tipo de identificador que você usa
  url: string; // URL da imagem
  isMain?: boolean; // Indica se é a imagem principal (opcional)
}


interface ImageViewerRequest {
  images: Image[]
}

const ImageViewer = ({ images }: ImageViewerRequest) => {
  const [mainImage, setMainImage] = useState(images.find(img => img.isMain) || images[0]);
  const thumbnailContainerRef = useRef<HTMLDivElement>(null);

  return (
    <ContainerImgViewer>
      <MainImageContainer>
        <img src={mainImage.url} alt="Imagem principal" loading='lazy'/>
      </MainImageContainer>
      <ContainerThumbnail>
        <ThumbnailContainer ref={thumbnailContainerRef}>
          {images.map((image, index) => (
            <Thumbnail
              key={index}
              src={image.url}
              alt={`Imagem ${index + 1}`}
              $isActive={image.url === mainImage.url}
              onClick={() => setMainImage(image)}
              loading='lazy'
            />
          ))}
        </ThumbnailContainer>
      </ContainerThumbnail>
    </ContainerImgViewer>
  )
}

export default ImageViewer;
