import axios, { AxiosError } from 'axios';
import Cookie from 'js-cookie';
import { AuthTokenError } from './errors/AuthTokenError';
import { AuthContext } from '../Context/Auth/AuthContext';
import { useContext } from 'react';
import { API_URL } from './Config/config';

export function createAPIClient() {
  const token = Cookie.get('@tokenAvaUser');

  const api = axios.create({
    baseURL: 'https://services-avaveiculos-8ab2745c7c21.herokuapp.com',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  api.interceptors.response.use(
    response => {
      return response;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    }
  );

  return api;
};

export function useAPIClient() {
  const { signOut } = useContext(AuthContext); 

  const api = createAPIClient(); 

  api.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        signOut();
      }
      return Promise.reject(error);
    }
  );

  return api; 
};
