import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { apiClient } from '../../../Services/apiClient';

interface RegisterAddressProps {
  companyId: number;
  onClose: () => void;
}

const RegisterAddress = ({ companyId, onClose }:RegisterAddressProps) => {
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postal_code, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const [complement, setComplement] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    try {
        const token = Cookies.get('@tokenAvaUser');
        if (!token) {
            throw new Error('Usuário não autenticado.');
        }

        await apiClient.post(`http://localhost:3333/company/${companyId}/address`, {
            street,
            number,
            district,
            city,
            state,
            postal_code,
            country,
            complement,
            company_id: companyId,
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        setSuccess('Endereço cadastrado com sucesso!');
        // Limpar os campos do formulário
        setStreet('');
        setNumber('');
        setDistrict('');
        setCity('');
        setState('');
        setPostalCode('');
        setCountry('');
        setComplement('');
    } catch (err) {
        console.error('Erro ao cadastrar endereço:', err);
        setError('Erro ao cadastrar o endereço. Por favor, tente novamente.');
    }
  };

  return (
    <div>
      <h2>Cadastrar Endereço</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Rua:</label>
          <input
            type="text"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Número:</label>
          <input
            type="text"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Bairro:</label>
          <input
            type="text"
            value={district}
            onChange={(e) => setDistrict(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Cidade:</label>
          <input
            type="text"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Estado:</label>
          <input
            type="text"
            value={state}
            onChange={(e) => setState(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Código Postal:</label>
          <input
            type="text"
            value={postal_code}
            onChange={(e) => setPostalCode(e.target.value)}
            required
          />
        </div>
        <div>
          <label>País:</label>
          <input
            type="text"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Complemento:</label>
          <input
            type="text"
            value={complement}
            onChange={(e) => setComplement(e.target.value)}
          />
        </div>
        <button type="submit">Cadastrar</button>
        <button type="button" onClick={onClose}>Fechar</button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>{success}</p>}
    </div>
  );
};

export default RegisterAddress;
