import { Colors } from './Colors/Colors';
import { Fonts } from "./Fonts";

const SettingsStyles = () => (
  <>
    <Fonts/>
    <Colors/>
  </>
)

export default SettingsStyles;