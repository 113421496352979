import { FaBell, FaExclamationCircle, FaUser, FaSignOutAlt } from "react-icons/fa";

export const MenuUserData = [
  {
    userOption: [
      { label: 'Notificações', action: '#', icon: <FaBell /> },
      { label: 'Pendências', action: '#', icon: <FaExclamationCircle /> },
      { label: 'Meu usuário', action: '#', icon: <FaUser /> },
      { label: 'Sair', action: '#', icon: <FaSignOutAlt /> },
    ],
  },
];