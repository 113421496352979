import { useState } from 'react';
import { useResetPassword } from '../../../../../Context/ResetPassword/ResetPasswordContext';
import { apiClient } from '../../../../../Services/apiClient';
import { Button, TextField, CircularProgress, Typography, IconButton, InputAdornment } from '@mui/material';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { Formik, Form, Field } from 'formik';
import { AxiosError } from 'axios';
import { validationNewPassword } from '../../../Schemas/NewPassWord';
import MaskCPF from '../../../../../Shared/Functions/Mask/MaskCPF';

export function NewPassword() {
  const { cpf, token, setStep } = useResetPassword();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = async (values: { newPassword: string, confirmPassword: string }) => {
    setLoading(true);

    try {
      const response = await apiClient.post('/reset-password', { cpf, token, newPassword: values.newPassword });
      setStep(4);
    } catch (error) {
      // Verifica se o erro é uma instância de AxiosError
      if (error instanceof AxiosError) {
        setErrorMessage(`Erro ao redefinir senha: ${error.response?.data?.message || error.message}`);
      } else {
        // Se não for um erro do Axios, exibe uma mensagem genérica
        setErrorMessage('Erro inesperado ao redefinir senha');
      }
    } finally {
      setLoading(false);
    }
  };


  return (
    <Formik
      initialValues={{
        newPassword: '',
        confirmPassword: '',
      }}
      validationSchema={validationNewPassword}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, handleBlur, touched, errors }) => (
        <Form>
          <div>
            <Field
              as={TextField}
              label="Seu CPF"
              value={MaskCPF(cpf)}
              fullWidth
              disabled
              margin="normal" 
            />
            <Field
              as={TextField}
              label="Token"
              value={token}
              fullWidth
              disabled
              margin="normal"
            />
            {/* Campo de Nova Senha */}
            <Field
              name="newPassword"
              as={TextField}
              label="Digite sua nova senha"
              type={showPassword ? 'text' : 'password'}
              value={values.newPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              margin="normal"
              variant="outlined"
              error={touched.newPassword && Boolean(errors.newPassword)}
              helperText={touched.newPassword && errors.newPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Field
              name="confirmPassword"
              as={TextField}
              label="Confirme sua nova senha"
              type={showConfirmPassword ? 'text' : 'password'}
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              margin="normal"
              variant="outlined"
              error={touched.confirmPassword && Boolean(errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      edge="end"
                    >
                      {showConfirmPassword ? <MdVisibilityOff /> : <MdVisibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {errorMessage && <Typography color="error">{errorMessage}</Typography>}
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={loading}
              sx={{ mt: 1 }}
            >
              {loading ? <CircularProgress size={24} /> : 'Redefinir Senha'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}