import { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Certifique-se de importar useNavigate
import { apiClient } from '../../../Services/apiClient';

import Cookies from 'js-cookie';
import {
  Container,
  Form,
  Label,
  Input,
  Button,
  ErrorMessageStyled,
  SuccessMessageStyled
} from './Styles/RegisterCompany.styles'; // Ajuste o caminho conforme necessário

const RegisterCompany = () => {
  const navigate = useNavigate(); // Inicializando o useNavigate
  const [formData, setFormData] = useState<CompanyData>({
    name: '',
    cpf_cnpj: '',
    opening_date: '',
    phone: '',
    email: '',
    contract: '',
    profile_image: '',
  });

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handlePdfChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setPdfFile(e.target.files[0]);
    }
  };

  const removeImage = () => {
    setFile(null);
  };

  const removePdf = () => {
    setPdfFile(null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);
    setLoading(true);

    try {
      const token = Cookies.get('@tokenAvaUser');
      if (!token) {
        throw new Error('Usuário não autenticado.');
      }

      const formDataToSend = new FormData();
      formDataToSend.append('name', formData.name);
      formDataToSend.append('cpf_cnpj', formData.cpf_cnpj);
      formDataToSend.append('opening_date', formData.opening_date);
      formDataToSend.append('phone', formData.phone);
      formDataToSend.append('email', formData.email);
      
      if (file) {
        formDataToSend.append('profileImageFile', file);
      }
      if (pdfFile) {
        formDataToSend.append('contractFile', pdfFile);
      }

      const response = await apiClient.post('/company', formDataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        setSuccess(true);
        setFormData({
          name: '',
          cpf_cnpj: '',
          opening_date: '',
          phone: '',
          email: '',
          contract: '',
          profile_image: '',
        });
        setFile(null);
        setPdfFile(null);
      }
    } catch (error) {
      console.error('Error creating company:', error);
      setError('Erro ao criar empresa. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <h2>Cadastrar Empresa</h2>
      {success && <SuccessMessageStyled>Empresa cadastrada com sucesso!</SuccessMessageStyled>}
      {error && <ErrorMessageStyled>{error}</ErrorMessageStyled>}

      <Form onSubmit={handleSubmit}>
        <div>
          <Label htmlFor="name">Nome:</Label>
          <Input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>

        <div>
          <Label htmlFor="cpf_cnpj">CPF/CNPJ:</Label>
          <Input
            type="text"
            id="cpf_cnpj"
            name="cpf_cnpj"
            value={formData.cpf_cnpj}
            onChange={handleInputChange}
            required
          />
        </div>

        <div>
          <Label htmlFor="opening_date">Data de Abertura:</Label>
          <Input
            type="date"
            id="opening_date"
            name="opening_date"
            value={formData.opening_date}
            onChange={handleInputChange}
            required
          />
        </div>

        <div>
          <Label htmlFor="phone">Telefone:</Label>
          <Input
            type="text"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            required
          />
        </div>

        <div>
          <Label htmlFor="email">Email:</Label>
          <Input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>

        <div>
          <Label htmlFor="profile_image">Selecionar Imagem de Perfil:</Label>
          <Input
            type="file"
            id="profile_image"
            accept="image/*"
            onChange={handleImageChange}
          />
          {file && (
            <div>
              <p>Imagem selecionada: {file.name}</p>
              <button type="button" onClick={removeImage}>Excluir Imagem</button>
              <img src={URL.createObjectURL(file)} alt="Preview" style={{ width: '100px', height: 'auto' }} />
            </div>
          )}
        </div>

        <div>
          <Label htmlFor="contract">Selecionar Contrato (PDF):</Label>
          <Input
            type="file"
            id="contract"
            accept=".pdf"
            onChange={handlePdfChange}
          />
          {pdfFile && (
            <div>
              <p>PDF selecionado: {pdfFile.name}</p>
              <button type="button" onClick={removePdf}>Excluir PDF</button>
            </div>
          )}
        </div>

        <Button type="submit" $isLoading={loading} disabled={loading}>
          {loading ? 'Cadastrando...' : 'Cadastrar'}
        </Button>

        {/* Botão para voltar ao dashboard */}
        <Button type="button" $isLoading={false} onClick={() => navigate('/dashboard')}>
          Voltar para o Dashboard
        </Button>
      </Form>
    </Container>
  );
};

export default RegisterCompany;
