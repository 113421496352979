import { useState } from 'react';
import { apiClient } from '../../../../../Services/apiClient';


import { Box, Button, TextField, Typography, CircularProgress } from '@mui/material';
import { Form, Formik } from 'formik';
import { RequestResetSchema } from '../../../Schemas/RequestReset';
import MaskCPF from '../../../../../Shared/Functions/Mask/MaskCPF';
import { MdErrorOutline } from 'react-icons/md';
import { ErrorsUsers } from '../../../../../Services/errors/ErrorsUsers';
import { useResetPassword } from '../../../../../Context/ResetPassword/ResetPasswordContext';

export function RequestReset() {
  const { setCpf, setStep } = useResetPassword();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  function removeMask(cpf: string) {
    return cpf.replace(/[^\d]/g, '');
  }

  const handleSubmit = async (values: { cpf: string }) => {
    setLoading(true);

    try {
      const cleanedCpf = removeMask(values.cpf);
      const response = await apiClient.post('/request-reset', { cpf: cleanedCpf });
      setCpf(cleanedCpf);
      setStep(2);
    } catch (error: any) {
      if (error instanceof Error) {
        setErrorMessage(ErrorsUsers(error));
      } else {
        setErrorMessage('Erro desconhecido.');
      }
    } finally {
      setLoading(false);
    }
  }

  function clearError() {
    setErrorMessage('');
  }

  return (
    <Box>
      <Formik
        initialValues={{ cpf: '' }}
        validationSchema={RequestResetSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form>
            <TextField
              fullWidth
              id="cpf"
              name="cpf"
              label="Login"
              placeholder="Digite seu cpf"
              value={MaskCPF(values.cpf)}
              onChange={(e) => {
                handleChange(e);
                clearError();
              }}
              onBlur={handleBlur}
              error={touched.cpf && Boolean(errors.cpf)}
              helperText={touched.cpf && errors.cpf}
              margin="normal"
            />
            {errorMessage && (
              <Typography color="error" sx={{ mt: 1, display: "flex", alignItems: "center" }}>
                <MdErrorOutline style={{ marginRight: 8 }} />
                {errorMessage}
              </Typography>
            )}
            <Button
              type='submit'
              variant='contained'
              color='primary'
              fullWidth
              disabled={loading}
              sx={{ mt: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : "Receber código"}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  )
};
