import { Box, ImageListItem, Stack, Typography } from '@mui/material';
import { ImageElementReset } from './Styles/OrietattionSteps';
import imgElement from '../../../../Assets/img/svg/manWithDoubt.svg';

export default function OrietationSteps() {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ display: 'flex', gap: 1, flexDirection: {xs: 'column-reverse', sm: 'row'} }}>
        <ImageListItem sx={{display: {xs:'none', sm: 'block'}}}>
          <ImageElementReset
            src={imgElement}
            alt='Homem com dúvida - AVA VEÍCULOS'
            loading='lazy'
          />
        </ImageListItem>
        <Stack
          direction="column"
          spacing={1}
          useFlexGap
          maxWidth={350}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: 36,
              fontWeight: 600,
              color: "var(--color-BluePrimary)",
              mb: 1,
              mt: 5,
            }}
          >
            Esqueceu seu login?
          </Typography>

          {/* Passos Orientativos */}
          <Box sx={{ mt: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 600, fontSize: 16, color: "var(--color-BluePrimary)" }}>
              Passo 1: Inserir seu login
            </Typography>
            <Typography variant="body1" color="var(--color-GreyText)" sx={{ ml: 2, fontSize: 14 }}>
              Preencha seu login para iniciarmos o processo de recuperação.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 600, fontSize: 16, color: "var(--color-BluePrimary)", mt: 2 }}>
              Passo 2: Receber o Token
            </Typography>
            <Typography variant="body1" color="var(--color-GreyText)" sx={{ ml: 2, fontSize: 14 }}>
              Após inserir seu login, você receberá um token para verificar sua identidade.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 600, fontSize: 16, color: "var(--color-BluePrimary)", mt: 2 }}>
              Passo 3: Criar uma nova senha
            </Typography>
            <Typography variant="body1" color="var(--color-GreyText)" sx={{ ml: 2, fontSize: 14 }}>
              Com o token em mãos, você poderá criar uma nova senha para acessar sua conta.
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  )
}