import {
  ButtonRegisteVeiculos,
  ContainerCheckbox,
  ContentInput,
  FormContainer,
  InputDescription,
  SelectVehicles,
  VehicleCheckedBox
} from './styles/VeihicleForm.styles';

import { useVehicleForm } from './useVehicleForm';
import { FormData } from '../../@types/types';
import { useState } from 'react';
import { displacements, brands, years, colors, types } from './data/vehicleData';

import { Box, Checkbox, FormControl, FormControlLabel, FormLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import CurrencyMaskCustom from '../../../../../Components/Mask/PriceMask';

interface VehicleFormProps {
  onSubmit: (data: FormData) => void;
}

const VehicleForm = ({ onSubmit }: VehicleFormProps) => {
  const { formData, handleChange, handleSubmit } = useVehicleForm(onSubmit);
  const [isLoading, setIsLoading] = useState(false);


  return (
    <FormContainer onSubmit={handleSubmit}>
      <fieldset>
        <ContentInput>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              type="text"
              variant="outlined"
              name="title"
              label="Título"
              value={formData.title}
              onChange={handleChange}
              required
              placeholder="Ex: (Marca, Modelo, Cilíndrada e Ano)"
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-number"
              variant='outlined'
              type="text"
              name="mileage"
              value={formData.mileage || ''}
              onChange={handleChange}
              required
              label="Quilometragem"
              placeholder="Ex: 15000"
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-text"
              variant="outlined"
              type="text"
              name="price"
              value={formData.price}
              onChange={handleChange}
              required
              label="Valor do veículo"
              placeholder="Digite o preço do veículo"
              slotProps={{
                input: {
                  inputComponent: CurrencyMaskCustom as any,
                }
              }}
            />
          </div>

          <Box
            sx={{
              display: 'flex',
              gap: 1,
            }}
          >
            <FormControl fullWidth >
              <FormLabel required sx={{
                ml: 1
              }}>
                Cilindrada
              </FormLabel>
              <Select
                id="displacement"
                name="displacement"
                value={formData.displacement}
                onChange={handleChange}
                required
                displayEmpty
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      overflowY: 'auto',
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <Typography variant="body2" color="var(--color-GreyText)">
                    Selecionar Cinlindrada
                  </Typography>
                </MenuItem>
                {displacements.map(cc => (
                  <MenuItem key={cc} value={cc}>
                    {cc}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <FormLabel required sx={{
                ml: 1
              }}>
                Marca
              </FormLabel>
              <Select
                id="brand"
                name="brand"
                value={formData.brand}
                onChange={handleChange}
                required
                displayEmpty
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      overflowY: 'auto',
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <Typography variant="body2" color="var(--color-GreyText)">
                    Selecionar Marca
                  </Typography>
                </MenuItem>
                {brands.map(brand => (
                  <MenuItem key={brand} value={brand}>
                    {brand}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <FormLabel required sx={{
                ml: 1
              }}>
                Ano
              </FormLabel>
              <Select
                id="year"
                name="year"
                value={formData.year}
                onChange={handleChange}
                required
                displayEmpty
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      overflowY: 'auto',
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <Typography variant="body2" color="var(--color-GreyText)">
                    Selecionar Ano
                  </Typography>
                </MenuItem>
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{
            display: 'flex',
            gap: 1,
          }}>
            <FormControl fullWidth>
              <FormLabel required sx={{
                ml: 1
              }}>
                Categoria
              </FormLabel>
              <Select
                id="type"
                name="type"
                value={formData.type}
                onChange={handleChange}
                required
                displayEmpty
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      overflowY: 'auto',
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <Typography variant="body2" color="var(--color-GreyText)">
                    Selecionar categoria
                  </Typography>
                </MenuItem>
                {types.map((type) => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <FormLabel required sx={{
                ml: 1
              }}>
                Cor
              </FormLabel>
              <Select
                id="color"
                name="color"
                value={formData.color}
                onChange={handleChange}
                required
                displayEmpty
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      overflowY: 'auto',
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <Typography variant="body2" color="var(--color-GreyText)">
                    Selecionar Cor
                  </Typography>
                </MenuItem>
                {colors.map((color) => (
                  <MenuItem key={color} value={color}>
                    {color}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <SelectVehicles>

          </SelectVehicles>
          <ContainerCheckbox>
            <VehicleCheckedBox>
              <label htmlFor="acceptsExchange">
                <input
                  type="checkbox"
                  name="acceptsExchange"
                  checked={formData.acceptsExchange}
                  onChange={handleChange}
                />
                <p>Aceita Troca</p>
              </label>
            </VehicleCheckedBox>
            <VehicleCheckedBox>
              <label htmlFor="status">
                <input
                  type="checkbox"
                  name="status"
                  checked={formData.status}
                  onChange={handleChange}
                />
                <p>Em estoque</p>
              </label>
              
            </VehicleCheckedBox>
          </ContainerCheckbox>
        </ContentInput>

        <InputDescription>
          <label htmlFor="description">Descrição:</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
            placeholder="Digite uma descrição detalhada do veículo"
          />
        </InputDescription>
      </fieldset>

      <ButtonRegisteVeiculos type="submit" disabled={isLoading}>
        {isLoading ? 'Carregando...' : 'Registrar Veículo'}
      </ButtonRegisteVeiculos>
    </FormContainer>
  );
};

export default VehicleForm;
