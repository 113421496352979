import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { apiClient } from '../../Services/apiClient';
import { Avatar, Skeleton } from '@mui/material';
import { StringAvatar } from './Functions/AvatarColor';

interface AvatarComponentProps {
  size?: number;
}

interface UserDetails {
  name: string;
  surname: string;
}

interface JwtPayload {
  sub: string; // ID do usuário
}

export default function AvatarComponent({ size = 40 }: AvatarComponentProps) {
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchUserDetails = async () => {
      setError(null); // Resetando o erro a cada nova tentativa

      try {
        const token = Cookies.get('@tokenAvaUser');
        if (!token) {
          throw new Error('Token não encontrado');
        }

        const decodedToken = jwtDecode<JwtPayload>(token);
        const { sub: userId } = decodedToken;
        const response = await apiClient.get(`/users/${userId}`);
        setUserDetails(response.data);
      } catch (err) {
        const errorMessage = (err as Error).message;
        setError(errorMessage); 
      } finally {
        setLoading(false); 
      }
    };

    const token = Cookies.get('@tokenAvaUser');
    if (token) {
      fetchUserDetails();
    } else {
      setLoading(false); 
    }
  }, []);

  return (
    <div>
      {loading ? (
        <Skeleton variant="circular" width={size} height={size} />
      ) : userDetails ? (
        <Avatar
          {...StringAvatar(`${userDetails.name} ${userDetails.surname}`)}
          sx={{ width: size, height: size, fontSize: size / 2.5 }}
        />
      ) : (
        <div>{error ? `Erro: ${error}` : 'Usuário não encontrado'}</div>
      )}
    </div>
  );
}
