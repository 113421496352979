export function ErrorsUsers(error: any){
  if (error.response) {
    const statusCode = error.response.status;
    const errorMessage = error.response.data?.error || 'Erro desconhecido';

    switch (statusCode) {
      case 400: 
        return "Dados inválidos!"
      case 401:
        return "Você não tem permissão para realizar essa ação!"
      case 404:
        return "Usuário não encontrado!";
      case 500:
        return "Erro interno do servidor!";
      default:
        return errorMessage;
    }
  }

  if (error.request) {
    return "Erro de conexão. Verifique sua internet ou tente novamente.";
  }

  return "Ocorreu um erro inesperado";
}