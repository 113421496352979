import { useState } from 'react';
import { apiClient } from '../../../Services/apiClient';

import VehicleForm from './Components/VehicleForm/VehicleForm';
import UploadImages from './Components/UpLoadImages/UploadImages';

const CreateVehicle = () => {
  const [vehicleId, setVehicleId] = useState<number | null>(null); 

  const handleSubmit = async (formData: any) => {
    try {
      const response = await apiClient.post('/vehicles', formData);
      console.log('Veículo criado:', response.data);
      setVehicleId(response.data.id);
    } catch (error) {
      console.error('Erro ao criar veículo:', error);
      alert('Ocorreu um erro ao criar o veículo. Tente novamente.');
    }
  };

  return (
    <div>
      {vehicleId === null ? (
        <VehicleForm onSubmit={handleSubmit} />
      ) : (
        <UploadImages vehicleId={vehicleId} /> 
      )}
    </div>
  );
};

export default CreateVehicle;
