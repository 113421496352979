import { createContext, useState, useContext, ReactNode } from 'react';

const ResetPasswordContext = createContext<ResetPasswordRequest | undefined>(undefined);

export const ResetPasswordProvider = ({children}: {children: ReactNode}) => {
  const [ cpf, setCpf ] = useState('');
  const [ token, setToken ] = useState('');
  const [ step, setStep ] = useState(1);

  return (
    <ResetPasswordContext.Provider value={{ cpf,token, step, setCpf, setToken, setStep}}>
      {children}
    </ResetPasswordContext.Provider>
  );
};

export const useResetPassword = () => {
  const context = useContext(ResetPasswordContext);
  if(!context) {
    throw new Error('useResetPassword deve ser usado dentro de um ResetPasswordProvider')
  };
  return context;
};