import { Box, CircularProgress, Container } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'; 

export default function Loading() {
  const navigate = useNavigate();

  useEffect(() => {

    const token = Cookies.get('@tokenAvaUser');
    
    if (token) {

      navigate('/dashboard');
    } else {

      navigate('/SingIn'); 
    }
  }, [navigate]); 

  
  return (
    <Container sx={{ bgcolor: '#cfe8fc', height: '100vh', width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box>
        <CircularProgress />
      </Box>
    </Container>
  );
}
