export default function MaskCPF(value: string) {
  // Remove qualquer caractere que não seja número
  const cleanedValue = value.replace(/\D/g, '');

  // Limita o valor a 11 números
  const limitedValue = cleanedValue.slice(0, 11);

  // Aplica a máscara no CPF com 11 números
  if (limitedValue.length <= 3) {
    return limitedValue;
  }
  if (limitedValue.length <= 6) {
    return `${limitedValue.slice(0, 3)}.${limitedValue.slice(3, 6)}`;
  }
  if (limitedValue.length <= 9) {
    return `${limitedValue.slice(0, 3)}.${limitedValue.slice(3, 6)}.${limitedValue.slice(6, 9)}`;
  }
  return `${limitedValue.slice(0, 3)}.${limitedValue.slice(3, 6)}.${limitedValue.slice(6, 9)}-${limitedValue.slice(9, 11)}`;
}
