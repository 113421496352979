import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #8C8C8C;
  font-family: var(--font-Roboto);
  width: 100%;
`;

export const Form = styled.form`
  background-color: white;
  padding: 2rem;
  width: 100%;
  max-width: 30rem; /* Limita a largura do formulário */
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const Label = styled.label`
  margin: 0.5rem 0;
  display: block;
  font-weight: 500;
  color: #333;
`;

export const Input = styled.input`
  border: 1px solid #c1c1c1;
  width: 100%;
  border-radius: 0.5rem;
  padding: 0.75rem;
  font-size: 1em;
  background-color: #F5F5F5;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:focus {
    border-color: #1C77BA;
    box-shadow: 0 0 8px rgba(28, 119, 186, 0.5);
    outline: none;
  }

  &:hover {
    background-color: #EDEDED;
  }
`;

export const Button = styled.button<{ $isLoading: boolean }>`
  background-color: ${(props) => (props.$isLoading ? '#012840' : '#1C77BA')};
  color: white;
  border: none;
  border-radius: 0.5rem;
  padding: 0.75rem;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #003E78;
  }

  &:disabled {
    background-color: #012840;
    cursor: not-allowed;
  }
`;

export const ErrorMessageStyled = styled.div`
  color: red;
  margin-top: 0.5rem;
  font-size: 0.875rem;
`;

export const SuccessMessageStyled = styled.div`
  color: green;
  margin-top: 0.5rem;
  font-size: 1em;
`;
