import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ResetPasswordProvider, useResetPassword } from "../../Context/ResetPassword/ResetPasswordContext";

import { ReturnLink } from "./styles/ResetPassword.styles";

import { IoIosArrowRoundBack } from "react-icons/io";

import { RequestReset } from './Components/FormSteps/RequestReset/RequestReset';
import { ValidateToken } from './Components/FormSteps/ValidateToken/ValidateToken';
import { NewPassword } from "./Components/FormSteps/NewPassword/NewPassword";
import { SuccessReset } from './Components/FormSteps/SucessReset/SucessReset';

import {
  Box,
  Container,
  Fade,
  Typography
} from "@mui/material";

import { LogoAvaVehicles } from '../../Components/CustomIcons/SvgAvaVeiculos';
import OrietationSteps from './Components/Content/OrietationSteps';

export function ResetPasswordFlow() {
  return (
    <ResetPasswordProvider>
      <ResetPasswordFlowContent />
    </ResetPasswordProvider>
  );
}

function ResetPasswordFlowContent() {
  const { step } = useResetPassword();
  const navigate = useNavigate();

  useEffect(() => {
    if (step === 4) {
      const timer = setTimeout(() => {
        navigate("/"); // Altere para o caminho da sua página inicial
      }, 4000);

      return () => clearTimeout(timer); // Limpa o timer ao desmontar o componente
    }
  }, [step, navigate]);

  return (
    <Container
      sx={{
        backgroundColor: 'var(--color-BackgroundAFWhite)',
        minHeight: '100vh',
        minWidth: '100vw',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: {xs: 'column', sm: 'row'},
        padding: { xs: 2, sm: 4}
      }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 5, flexDirection: {xs: 'column-reverse', sm: 'row'}}}>
        <OrietationSteps />
        <Box
          sx={{
            maxWidth: 450,
            height: "auto",
            margin: "auto",
            p: 2,
            boxShadow: 3,
            borderRadius: 2,
            bgcolor: "background.paper",
          }}
        >
          <Box sx={{ pt: 2, display: 'flex', justifyContent: 'center', mb: 1 }}>
            <LogoAvaVehicles />
          </Box>
          <Typography color='var(--color-BluePrimary)' component="h5" variant='h3' fontSize={20} textAlign="center" mb={2}>
            Bem vindo ao sistema AVA Veículos
          </Typography>

          {/* Etapa 1 - RequestReset */}
          <Fade in={step === 1} timeout={500}>
            <div style={{ display: step === 1 ? "block" : "none" }}>
              <RequestReset />
            </div>
          </Fade>

          {/* Etapa 2 - ValidateToken */}
          <Fade in={step === 2} timeout={500}>
            <div style={{ display: step === 2 ? "block" : "none" }}>
              <ValidateToken />
            </div>
          </Fade>

          {/* Etapa 3 - NewPassword */}
          <Fade in={step === 3} timeout={500}>
            <div style={{ display: step === 3 ? "block" : "none" }}>
              <NewPassword />
            </div>
          </Fade>

          {/* Etapa 4 - SuccessReset */}
          <Fade in={step === 4} timeout={500}>
            <div style={{ display: step === 4 ? "block" : "none" }}>
              <SuccessReset />
            </div>
          </Fade>
          <ReturnLink to=".." className="ReturnRequestReset">
            <IoIosArrowRoundBack size={22} /> Voltar para página inicial
          </ReturnLink>
        </Box>
      </Box>
    </Container>
  );
};