import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { apiClient } from '../../../Services/apiClient';
import RegisterAddress from '../RegisterAddress/RegisterAddress';
import { 
  CompanyDetailsContainer, 
  AddressSection, 
  ButtonContainer, 
  BackToDashboardButton 
} from './Styles/CompanyDetail.styles';


interface Company {
  id: number;
  name: string;
  cpf_cnpj: string;
  opening_date: string;
  phone: string;
  email: string;
  address?: {
    street: string;
    city: string;
    state: string;
    zipCode: string;
  };
}

const CompanyDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [company, setCompany] = useState<Company | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isRegisteringAddress, setIsRegisteringAddress] = useState<boolean>(false);

  const navigate = useNavigate(); // Usado para navegação de volta

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const token = Cookies.get('@tokenAvaUser');
        if (!token) {
          throw new Error('Usuário não autenticado.');
        }

        const response = await apiClient.get(`http://localhost:3333/company/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setCompany(response.data);
      } catch (err) {
        console.error('Error fetching company details:', err);
        setError('Erro ao carregar os detalhes da empresa. Por favor, tente novamente.');
      } finally {
        setLoading(false);
      }
    };

    fetchCompany();
  }, [id]);

  const handleRegisterAddressClick = () => {
    setIsRegisteringAddress(true);
  };

  const handleCloseRegisterAddress = () => {
    setIsRegisteringAddress(false);
  };

  if (loading) return <p>Carregando...</p>;
  if (error) return <p>{error}</p>;

  return (
    <CompanyDetailsContainer>
      <h1>Detalhes da Empresa</h1>
      {company && (
        <div>
          <p><strong>Nome:</strong> {company.name}</p>
          <p><strong>CPF/CNPJ:</strong> {company.cpf_cnpj}</p>
          <p><strong>Abertura:</strong> {company.opening_date}</p>
          <p><strong>Telefone:</strong> {company.phone}</p>
          <p><strong>Email:</strong> {company.email}</p>
          {company.address ? (
            <AddressSection>
              <h3>Endereço</h3>
              <p><strong>Rua:</strong> {company.address.street}</p>
              <p><strong>Cidade:</strong> {company.address.city}</p>
              <p><strong>Estado:</strong> {company.address.state}</p>
              <p><strong>Código Postal:</strong> {company.address.zipCode}</p>
            </AddressSection>
          ) : (
            <div>
              <p>Endereço não cadastrado.</p>
              <ButtonContainer>
                <button onClick={handleRegisterAddressClick}>Cadastrar Endereço</button>
              </ButtonContainer>
            </div>
          )}
        </div>
      )}

      {isRegisteringAddress && (
        <RegisterAddress companyId={company?.id!} onClose={handleCloseRegisterAddress} />
      )}

      {/* Botão para voltar ao dashboard */}
      <BackToDashboardButton onClick={() => navigate('/allcompanies')}>
        Voltar a lista
      </BackToDashboardButton>
    </CompanyDetailsContainer>
  );
};

export default CompanyDetails;