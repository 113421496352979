import { useState, useEffect } from 'react';
import { Container, Text } from './Styles/SucessMessage.styles';
import IconAnimation from '../../../../../../../Components/Animations/IconAnimation/IconAnimation';

export default function SuccessMessage () {

  const [textVisible, setTextVisible] = useState(false);

  useEffect(() => {
    // Mostra o texto após 2 segundos
    const textTimeout = setTimeout(() => setTextVisible(true), 1000);

    // Cleanup
    return () => {
      clearTimeout(textTimeout);
    };
  }, []);

  return (
    <Container>
      <IconAnimation/>
      <Text visible={textVisible}>
        <strong>Tudo certo!</strong> 
        <p>Seu login já foi resetado.</p>
        <p>Você será redirecionado.</p>
      </Text>
    </Container>
  );
};