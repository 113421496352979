import styled from 'styled-components';
import { Link } from 'react-router-dom';


export const Container = styled.div`
  display: flex;
`

export const CustomLink = styled(Link)`
  text-decoration: none;
  color: var(--color-BluePrimary);
  cursor: pointer;
  font-family: var(--font-Roboto);
  display: flex;
  font-size: 1.1em;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;

  &:hover {
    text-decoration: underline;
  }
`
