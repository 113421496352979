import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { apiClient } from '../../../Services/apiClient';
import { 
  CompaniesContainer, 
  CompaniesTable, 
  TableRow, 
  DetailButton, 
  Title, 
  BackButton 
} from './Styles/AllCompanies.styles';


interface Company {
  id: number;
  name: string;
  cpf_cnpj: string;
  opening_date: string;
  phone: string;
  email: string;
}

const AllCompanies = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const token = Cookies.get('@tokenAvaUser');
        if (!token) {
          throw new Error('Usuário não autenticado.');
        }

        const response = await apiClient.get('http://localhost:3333/companies', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setCompanies(response.data);
      } catch (err) {
        console.error('Error fetching companies:', err);
        setError('Erro ao carregar empresas. Por favor, tente novamente.');
      } finally {
        setLoading(false);
      }
    };

    fetchCompanies();
  }, []);

  const handleViewDetails = (id: number) => {
    navigate(`/company/${id}`);
  };

  const handleBackToDashboard = () => {
    navigate('/dashboard');
  };

  if (loading) return <p>Carregando...</p>;
  if (error) return <p>{error}</p>;

  return (
    <CompaniesContainer>
      <Title>Lista de Empresas</Title>
      <CompaniesTable>
        <thead>
          <tr>
            <th>Nome</th>
            <th>CPF/CNPJ</th>
            <th>Telefone</th>
            <th>Email</th>
            <th>Ação</th>
          </tr>
        </thead>
        <tbody>
          {companies.map((company, index) => (
            <TableRow key={company.id} isEven={index % 2 === 0}>
              <td>{company.name}</td>
              <td>{company.cpf_cnpj}</td>
              <td>{company.phone}</td>
              <td>{company.email}</td>
              <td>
                <DetailButton onClick={() => handleViewDetails(company.id)}>
                  Ver Detalhes
                </DetailButton>
              </td>
            </TableRow>
          ))}
        </tbody>
      </CompaniesTable>
      <BackButton onClick={handleBackToDashboard}>Voltar para o Dashboard</BackButton>
    </CompaniesContainer>
  );
};

export default AllCompanies;
