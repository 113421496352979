import SingInCard from './Components/SignInCard/SignInCard';
import Content from './Components/Content/Content';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

const SignSide = () => {
  return (
    <Container
      sx={{
        backgroundColor: 'var(--color-BackgroundAFWhite)',
        minHeight: '100vh',
        minWidth: '100vw',         
        display: 'flex',                 
        justifyContent: 'center',

      }}
    >
      <Stack
        direction="column"
        component="main"
        sx={{
          justifyContent: 'center',
          height: 'calc((1 - var(--template-frame-height, 0)) * 100%)',
          marginTop: 'max(40px - var(--template-frame-height, 0px), 0px)',
          minHeight: '100%',
        }}
      >
        <Stack
          direction={{ xs: 'column-reverse', md: 'row' }}
          sx={{
            justifyContent: 'center',
            gap: { xs: 6, sm: 12 },
            p: { xs: 2, sm: 4 },
            m: 'auto'
          }}
        >
          <Content />
          <SingInCard />
        </Stack>
      </Stack>
    </Container>
  );
};

export default SignSide;
