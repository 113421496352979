import styled from 'styled-components';

export const ContainerVehicleDetails = styled.div`
    width: 100%;
`

export const ContainerImagemTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  h1 {
    font-weight: bold;
  }
`

// Container principal para detalhes do veículo
export const DetailContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 6rem;
  height: auto;
  font-family: var(--font-Roboto); 
`;


export const ContentVehicleDetails = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  gap:  1rem;
  padding: 0rem 1rem ;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
`

// Container para o visualizador de imagens
export const ImageViewerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff; // Cor de fundo do visualizador
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-right: 20px; // Margem à direita
  width: 35rem; // Largura fixa do visualizador
`;

// Imagem principal
export const MainImage = styled.img`
  width: 100%; // Imagem principal ocupa toda a largura do container
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
`;

// Thumbnails
export const Thumbnail = styled.img`
  width: 100px; // Tamanho das miniaturas
  height: auto;
  border-radius: 4px;
  cursor: pointer;
  margin: 5px;

  &:hover {
    opacity: 0.7; // Efeito de hover nas miniaturas
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  font-family: var(--font-Roboto);

  h2 {
    padding: 2rem 0;
    font-size: 2em;
    font-weight: bold;
    color: #0286ea;
  }

  div {
    margin-bottom: 10px; // Espaçamento entre cada par
  }

  label {
    font-weight: bold; // Destacar os labels
    color: #333; // Cor do texto do label
  }

  p {
    margin: 0; // Remover margem do parágrafo
    color: #555; // Cor do texto da informação
  }

  .button-container {
    display: flex; // Flexbox para alinhar os botões lado a lado
    gap: 10px; // Espaçamento entre os botões
    margin-top: 10px; // Espaçamento acima dos botões
    

    button {
      padding: 10px 15px; // Estilo do botão
      border: none; // Sem borda
      border-radius: 5px; // Bordas arredondadas
      background-color: #0286ea; // Cor de fundo
      color: #fff; // Cor do texto
      cursor: pointer; // Mãozinha ao passar o mouse
      transition: background-color 0.3s; // Transição suave para hover
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.1em;

      &:hover {
        background-color: #0276d4; // Cor de fundo ao passar o mouse
      }

      svg {
        margin-left: 5px; // Espaçamento entre o texto e o ícone
      }
    }
  }
`;


// Título das informações
export const VehicleTitle = styled.h1`
  font-size: 2rem;
  color: #0286ea;
  margin: 1rem;
`;
