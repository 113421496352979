import AppRoutes from './Routes/AppRoutes';
import GlobalStyles from './Styles';

function App() {
  return (
    <>
      <GlobalStyles />
      <AppRoutes />
    </>
  );
}

export default App;
