import styled from 'styled-components';

export const CompanyDetailsContainer = styled.div`
  font-family: var(--font-Roboto);
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;

  h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #333;
  }

  p {
    margin: 0.5rem 0;
    color: #555;
  }

  strong {
    color: #000;
  }
`;

export const AddressSection = styled.div`
  margin-top: 1rem;

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #1C77BA;
  }

  p {
    margin: 0.3rem 0;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 10px;

  button {
    background-color: #1C77BA;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    font-size: 1rem;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #155a8a;
    }

    &:focus {
      outline: none;
    }
  }
`;

export const FilePreviewContainer = styled.div`
  margin-top: 1rem;

  img {
    max-width: 150px;
    max-height: 150px;
    object-fit: cover;
    margin-right: 10px;
    border: 1px solid #ddd;
    padding: 5px;
  }

  button {
    margin-top: 10px;
    background-color: red;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;

    &:hover {
      background-color: darkred;
    }
  }
`;

export const BackToDashboardButton = styled.button`
  margin-top: 20px;
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;
