/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";

// Animações
const drawCircle = keyframes`
  from {
    stroke-dashoffset: 1256;
  }
  to {
    stroke-dashoffset: 0;
  }
`;

const drawCheckmark = keyframes`
  from {
    stroke-dashoffset: 300;
  }
  to {
    stroke-dashoffset: 0;
  }
`;

// Estilo do SVG
const svgStyles = css`
  width: 5rem;
  height: auto;
  .circle {
    stroke: #4caf50;
    stroke-width: 15;
    fill: none;
    stroke-dasharray: 1256;
    stroke-dashoffset: 1256;
    animation: ${drawCircle} 2s ease-out forwards;
  }
  .checkmark {
    stroke: #4caf50;
    stroke-width: 15;
    fill: none;
    stroke-linecap: round;
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    animation: ${drawCheckmark} 1s ease-out 2s forwards;
  }
`;

const IconAnimation = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      css={svgStyles}
    >
      <circle cx="256" cy="256" r="200" className="circle" />
      <path d="M150 250 L230 330 L370 190" className="checkmark" />
    </svg>
  );
};

export default IconAnimation;
