import { createContext, useContext, useEffect, useState } from 'react';

import { apiClient } from '../../Services/apiClient';

import { jwtDecode } from 'jwt-decode';

import Cookies from 'js-cookie';

export const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export default function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<UserProps | undefined>(undefined);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [companyId, setCompanyId] = useState<string | undefined>(undefined);

  const decodeToken = (token: string) => {
    const decoded: { sub: string; companyId: string } = jwtDecode(token);
    return { userId: decoded.sub, companyId: decoded.companyId };
  };

  useEffect(() => {
    const token = Cookies.get('@tokenAvaUser');
    if (token) {
      try {
        const { userId, companyId } = decodeToken(token);
        setUser({ id: userId, name: '', companyId, cpf: '' });
        setCompanyId(companyId);
        setIsAuthenticated(true);
      } catch (err) {
        console.error("Erro ao decodificar token:", err);
        signOut();  // Remove o token caso o decode falhe
      }
    } else {
      signOut();  // Se não houver token, faz logout
    }
  }, []);

  const signIn = async (credentials: SignInProps) => {
    try {
      const response = await apiClient.post('/auth', credentials);
      const { token, ...userData } = response.data;

      Cookies.set('@tokenAvaUser', token, { expires: 1});
      const { userId, companyId } = decodeToken(token);
      setUser({ ...userData, id: userId, companyId });
      setCompanyId(companyId);
      setIsAuthenticated(true);
    } catch (error: any) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 404) {
          throw new Error(`Usuário não cadastrado.`);
        }
        if (status === 401) {
          throw new Error(`Login/Senha está incorreto.`);
        }
        if (status === 500) {
          throw new Error(`Erro interno no servidor. Tente novamente mais tarde.`);
        }
      }
      throw new Error('Erro desconhecido. Tente novamente.');
    }
  };
  

  const signOut = () => {
    Cookies.remove('@tokenAvaUser');
    setUser(undefined);
    setCompanyId(undefined);
    setIsAuthenticated(false);
  };

  const signUp = async (credentials: SignUpProps) => {
    await apiClient.post('/users', credentials);
  };

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, signIn, signOut, signUp, companyId }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth deve ser usado dentro do AuthProvider');
  }
  return context;
};
