import { Box, IconButton, Drawer, Typography, Divider, LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { LogoAvaVehicles } from '../../../../../../Components/CustomIcons/SvgAvaVeiculos';
import MenuList from './MenuList/MenuList';
import packageInfo from '../../../../../../../package.json';
import { IoMenuOutline } from "react-icons/io5";
import { MdOutlineMenuOpen } from "react-icons/md";
import { apiClient } from '../../../../../../Services/apiClient';
import { useAuth } from '../../../../../../Context/Auth/AuthContext';

export default function Sidebar() {
  const [open, setOpen] = useState(false);
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [loading, setLoading] = useState(true);  // Estado de loading
  const { isAuthenticated } = useAuth(); // Usa o contexto de autenticação

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = Cookies.get('@tokenAvaUser');
        if (!token) throw new Error('Token não encontrado');

        const response = await apiClient.get('/users/me');
        setUserDetails(response.data);
      } catch (err) {
        console.error('Erro ao buscar dados do usuário:', err);
      } finally {
        setLoading(false);  // Definindo loading como false após a requisição
      }
    };

    if (isAuthenticated) {
      setLoading(true);  // Inicia o loading antes de buscar os detalhes do usuário
      fetchUserDetails();
    }
  }, [isAuthenticated]); // A dependência é `isAuthenticated`

  return (
    <Box>
      {/* Ícone do menu */}
      <IconButton color="primary" onClick={() => setOpen(true)} sx={{ mr: 2 }}>
        <IoMenuOutline size={38} />
      </IconButton>

      {/* Drawer do menu */}
      <Drawer open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: 300,
            maxWidth: 320,
          }}
        >
          {/* Cabeçalho do Drawer */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="subtitle2">Empresa</Typography>
              <Typography
                variant="body1"
                sx={{
                  color: 'var(--color-BluePrimary)',
                  fontSize: 22,
                  fontWeight: 'bold',
                }}
              >
                {loading ? <LinearProgress /> : userDetails?.company?.name}
              </Typography>
            </Box>
            <Box>
              <IconButton color="primary" onClick={() => setOpen(false)}>
                <MdOutlineMenuOpen size={32} />
              </IconButton>
            </Box>
          </Box>
          <Divider />

          {/* Lista de menu */}
          <MenuList />

          {/* Rodapé do Drawer */}
          <Box
            sx={{
              mt: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 1,
              pt: 0.1,
              pb: 0.1,
              borderTop: '1px solid #e0e0e0',
            }}
          >
            <Box>
              <LogoAvaVehicles width={110} height={60} />
            </Box>
            <Typography variant="body2">Versão {packageInfo.version}</Typography>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
