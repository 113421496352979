import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack
} from '@mui/material';

import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuLinksData } from './Data/MenuLinksData';
import React from 'react';

export default function MenuLinks() {
  const [openMenu, setOpenMenu] = useState<string | null>(null);
  const [anchorRefs, setAnchorRefs] = useState<{ [key: string]: React.RefObject<HTMLButtonElement> }>({});
  const navigate = useNavigate();
  const timerRef = useRef<any>(null);

  const handleMouseEnter = (menuLabel: string) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setOpenMenu(menuLabel);
  };

  const handleMouseLeave = () => {
    timerRef.current = setTimeout(() => {
      setOpenMenu(null);
    }, 5000);
  };


  const handleClose = (event: Event | SyntheticEvent) => {
    setOpenMenu(null);
  };


  const handleToggle = (menuLabel: string) => {
    setOpenMenu((prevOpenMenu) =>
      prevOpenMenu === menuLabel ? null : menuLabel
    );
  };

  function handleListKeyDown(event: React.KeyboardEvent<HTMLUListElement>) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenMenu(null);
    } else if (event.key === 'Escape') {
      setOpenMenu(null);
    };
  };

  const prevOpen = useRef(openMenu);
  useEffect(() => {
    if (prevOpen.current !== openMenu) {
      prevOpen.current = openMenu;
    }
  }, [openMenu]);


  useEffect(() => {
    const newAnchorRefs: { [key: string]: React.RefObject<HTMLButtonElement> } = {};
    MenuLinksData.forEach((menu) => {
      newAnchorRefs[menu.buttonLabel] = React.createRef();
    });
    setAnchorRefs(newAnchorRefs);
  }, []);

  return (
    <Stack direction='row' sx={{mr: 1.5}}>
      {MenuLinksData.map((menu, index) => (
        <div key={index}>
          <Button
            ref={anchorRefs[menu.buttonLabel]}
            id={`${menu.buttonLabel}-button`}
            aria-controls={openMenu === menu.buttonLabel ? `${menu.buttonLabel}-menu` : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu === menu.buttonLabel ? "true" : undefined}
            onMouseEnter={() => handleMouseEnter(menu.buttonLabel)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleToggle(menu.buttonLabel)}
            sx={{
              fontWeight: 400,
              textTransform: 'capitalize',
              fontSize: '1em',
              color: 'var(--color-GreyText)',
              '&:hover': {
                color: 'var(--color-BluePrimary)',
              },
            }}
          >
            {menu.buttonLabel}
          </Button>
          <Popper
            open={openMenu === menu.buttonLabel}
            anchorEl={anchorRefs[menu.buttonLabel]?.current}
            role={undefined}
            placement='bottom-start'
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={openMenu === menu.buttonLabel}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      {menu.menuItems?.map((item, idx) => (
                        <MenuItem
                          key={idx}
                          onClick={() => {
                            navigate(item.action);
                            handleClose(new Event("close"));
                          }}
                          sx={{
                            fontSize: '0.9em',
                            color: 'var(--color-GreyText)',
                            '&:hover': {
                              color: 'var(--color-BluePrimary)',
                              fontWeight: '600'
                            },
                          }}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      ))}
    </Stack>
  );
};
