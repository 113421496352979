import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ReturnLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  gap: 0.1rem;
  font-size: 1.1em;
  text-decoration: none;
  color: var(--color-BluePrimary);
  cursor: pointer;
  font-weight: 400;
  font-family: var(--font-Roboto);

  &:hover {
    text-decoration: underline;
  }
`
export const ImageElementReset = styled.img`
  width: 100%;
  height: 360px;
`