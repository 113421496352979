import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiClient } from '../../../Services/apiClient';
import Cookies from 'js-cookie';
import { FaTrash, FaEdit, FaEye } from 'react-icons/fa';
import { ActionButton, Table, TableWrapper, Title, VehiclesContainer } from './styles/ListVehicles.styles';

import { jwtDecode } from 'jwt-decode';

export interface VehicleProps {
  id: string;
  title: string;
  description: string;
  year: number;
  mileage: number;
  price: number;
  color: string;
  displacement: number;
  acceptsExchange: boolean;
  company_id: string;
  status: boolean;
}

const VehiclesPage = () => {
  const [vehicles, setVehicles] = useState<VehicleProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVehicles = async () => {
      setLoading(true);
      setError(null);

      try {
        const token = Cookies.get('@tokenAvaUser');
        if (!token) {
          throw new Error('Token não encontrado. Por favor, faça login novamente.');
        }

        // Decodificando o token completo para inspecionar sua estrutura
        const decodedToken = jwtDecode<{companyId: string}>(token);
        console.log('Token decodificado:', decodedToken);

        // Extraindo corretamente o companyId com "I" maiúsculo
        const companyId = decodedToken.companyId; 
        console.log('Company ID decodificado:', companyId);

        if (!companyId) {
          throw new Error('ID da empresa não encontrado nos detalhes do usuário.');
        }

        // Fazendo a requisição para obter os veículos da empresa
        const response = await apiClient.get(`vehicles?companyId=${companyId}`);
        console.log('Resposta da API:', response);
        console.log('Veículos recebidos:', response.data);

        setVehicles(response.data);
      } catch (err) {
        console.error('Erro ao buscar veículos:', err);
        const errorMessage = (err as Error).message;
        setError(`Erro: ${errorMessage}`);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicles();
  }, []); // companyId é obtido dentro do useEffect, então não precisa ser uma dependência aqui

  const handleViewDetails = (id: string) => {
    navigate(`/vehicles/${id}`); // Navegar para a página de detalhes
  };


  //DELETE VEHICLE SERVICES

  const deleteVehicle = async (id: string) => {
    try {
      const confirmDelete = window.confirm("Tem certeza que deseja excluir esse veículo?");
      if (!confirmDelete) return;
  
      const token = Cookies.get('@tokenAvaUser');
      if (!token) {
        throw new Error('Token não encontrado. Por favor, faça login novamente.');
      }
  
      const response = await apiClient.delete(`/vehicle/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Verificar se a exclusão foi bem-sucedida
      if (response.status === 200) {
        setVehicles((prevVehicles) => prevVehicles.filter(vehicle => vehicle.id !== id));
        alert('Veículo excluído com sucesso.');
      } else {
        // Melhorar a mensagem de erro aqui
        throw new Error(`Falha ao excluir o veículo. Status: ${response.status}`);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Erro ao excluir veículo:', error.message);
        alert(`Erro ao excluir veículo: ${error.message}`);
      } else {
        console.error('Erro desconhecido ao excluir veículo:', error);
        alert('Erro desconhecido ao excluir veículo. Tente novamente mais tarde.');
      }
    }
  };
  
  
  

  const handleDelete = (id: string) => {
    deleteVehicle(id);
  };

  const handleEdit = (id: string) => {
    console.log(`Editar veículo de ID: ${id}`);
    // Lógica de edição será implementada
  };

 

  if (loading) return <div role="alert">Carregando...</div>;
  if (error) return <div role="alert">{error}</div>;
  if (!vehicles.length) return <div role="alert">Nenhum veículo encontrado.</div>;

  return (
    <div>
      <VehiclesContainer>
        <Title>Lista de Veículos</Title>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <th>Título</th>
                <th>Ano</th>
                <th>Quilometragem (km)</th>
                <th>Preço (R$)</th>
                <th>Cor</th>
                <th>Cilindrada (cc)</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {vehicles.map((vehicle) => (
                <tr key={vehicle.id}>
                  <td>{vehicle.title}</td>
                  <td>{vehicle.year}</td>
                  <td>{vehicle.mileage}</td>
                  <td>R$ {vehicle.price.toFixed(2)}</td>
                  <td>{vehicle.color}</td>
                  <td>{vehicle.displacement} cc</td>
                  <td>
                    <ActionButton onClick={() => handleViewDetails(vehicle.id)}>
                      <FaEye />
                    </ActionButton>
                    <ActionButton onClick={() => handleEdit(vehicle.id)}>
                      <FaEdit />
                    </ActionButton>
                    <ActionButton onClick={() => handleDelete(vehicle.id)}>
                      <FaTrash />
                    </ActionButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
      </VehiclesContainer>
    </div>
  );
};

export default VehiclesPage;
