import styled from 'styled-components';

// Container do formulário
export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 2rem; /* Adiciona um padding interno para espaçar os componentes */

  fieldset {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    gap: 1.5rem;
     /* Remove a borda padrão */
    margin: 0;
  }
`;

// Estilização dos inputs de texto do veículo
export const InputTextVehicle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    label {
      font-size: 1rem;
      color: #555; /* Cor do texto do label */
    }

    input {
      padding: 0.8rem;
      width: 100%;
      font-size: 1rem;
      border-radius: 8px;
      border: 1px solid #ccc;
      background-color: #f9f9f9;
      transition: border-color 0.3s ease, box-shadow 0.3s ease;

      &:focus {
        border-color: #007BFF;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.4);
        outline: none;
      }
    }
  }
`;

// Estilização dos selects
export const StyledSelect = styled.select`
  padding: 0.8rem;
  width: 100%;
  font-size: 1rem;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  color: #333;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  
  &:focus {
    border-color: #007BFF;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.4);
    outline: none;
  }

  option {
    padding: 0.8rem;
  }
  
  appearance: none; /* Remove a aparência padrão do select */
`;

export const InputDescription = styled.div`
  textarea {
    width: 100%;
    height: 12rem;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    font-size: 1rem;
    color: #333;
    resize: vertical;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;

    &:focus {
      border-color: #007BFF;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.4);
      outline: none;
    }
  }
`;

// Container para o conteúdo do input
export const ContentInput = styled.div`
  width: 60%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

// Estilo do select de veículos
export const SelectVehicles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  label {
    font-size: 1rem;
    color: #555;
  }

  select {
    padding: 0.8rem;
    width: 100%;
    font-size: 1rem;
    border-radius: 8px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;

    &:focus {
      border-color: #007BFF;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.4);
      outline: none;
    }
  }
`;

// Container de caixas de seleção
export const ContainerCheckbox = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin: 1rem 0;
`;

// Estilo de cada caixa de seleção
export const VehicleCheckedBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    color: #555;
  }

  input[type="checkbox"] {
    transform: scale(1.2);
    transition: transform 0.2s ease;
  }

  input[type="checkbox"]:checked {
    background-color: #007BFF;
  }

  input[type="checkbox"]:hover {
    transform: scale(1.3);
  }
`;

// Botão de registro de veículos
export const ButtonRegisteVeiculos = styled.button`
  padding: 1rem;
  background-color: #007BFF;
  color: #fff;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;
