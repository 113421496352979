import { css, keyframes } from "@emotion/react";
import styled from '@emotion/styled';


export const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const fillAnimation = keyframes`
  from {
    stroke-dashoffset: 90;
  }
  to {
    stroke-dashoffset: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  font-family: var(--font-Roboto);
  gap: 0.5rem;
`

export const Text = styled.div<{ visible: boolean }>`
  display: flex;
  flex-direction: column;
  font-size: 1.1em;
  gap: 0.1rem;
  text-align: center;

  strong {
    color: var(--color-BluePrimary);
    font-weight: bold;
    font-size: 1.5em;
  }

  p {
    color: var(--color-GreyText);
  }

  opacity: ${(props) => (props.visible ? "1" : "0")};
  animation: ${(props) =>
    props.visible &&
    css`
      ${fadeIn} 1.5s ease-out forwards
    `};
`;