import { AppBar, Container, Divider, Toolbar, Typography, Box } from '@mui/material';
import { LogoAvaVehicles } from '../../../../Components/CustomIcons/SvgAvaVeiculos';
import Sidebar from './Components/Sidebar/Sidebar';
import MenuLinks from './Components/MenuLinks/MenuLinks';
import MenuUser from './Components/MenuUser/MenuUser';


export default function ComponentHeader() {

  return (
    <AppBar position='static' sx={{ bgcolor: '#fff' }}>
      <Container maxWidth='xl' >
        <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Sidebar />
            <Divider
              orientation='vertical'
              variant='middle'
              flexItem
              sx={{
                display: { xs: 'none', sm: 'none', md: 'flex' }
              }}
            />
            <Typography 
            variant='h3' 
            component='a' 
            href='/dashboard' 
            sx={{ 
              p: 0, 
              ml: { xs: 0, sm: 1, md: 3 },
              display: 'flex',
              alignItems: 'center',
              }}>
              <LogoAvaVehicles width={120} height={'auto'} />
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{
              display: { xs: 'none', sm: 'none', md: 'flex' }
            }}>
              <MenuLinks />
            </Box>
            <Divider
              orientation='vertical'
              variant='middle'
              flexItem
              sx={{
                display: { xs: 'none', sm: 'none', md: 'flex' }
              }}
            />
            <MenuUser />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
