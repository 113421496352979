import styled from 'styled-components';

// Contêiner principal que agrupa o visualizador
export const ContainerImgViewer = styled.div`
  display: flex;
  flex-direction: column; // Mudamos para coluna para colocar a imagem em cima e as miniaturas embaixo
  width: 100%; // Certifique-se de que o contêiner ocupe toda a largura disponível
`;

export const MainImageContainer = styled.div`
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto; /* Defina uma altura fixa para a imagem principal, conforme necessário */
  overflow: hidden; /* Garante que a imagem não ultrapasse o contêiner */


  img {
    width: 25rem; /* A imagem ocupa toda a largura do contêiner */
    height: 30rem; /* Garante que a altura da imagem não ultrapasse o contêiner */
    object-fit: cover; /* Corta a imagem para preencher o contêiner sem distorção */
  }
`;


export const ContainerThumbnail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;


// Contêiner para as miniaturas
export const ThumbnailContainer = styled.div`
  display: flex;
  flex-direction: row; // Mudamos para linha para que as miniaturas se alinhem horizontalmente
  overflow-x: auto; // Permite rolagem horizontal
  gap: 0.5rem; // Espaçamento entre miniaturas
  padding: 0.5rem; // Adiciona padding ao redor das miniaturas
`;

// Estilo para as miniaturas
export const Thumbnail = styled.img<{$isActive: boolean}>`
  width: 4rem;
  border-radius: 5px;
  cursor: pointer;
  transition: border 0.3s, transform 0.2s;
  opacity: ${(props) => (props.$isActive ? 1 : 0.6)};
  border: ${(props) => (props.$isActive ? '2px solid black' : '2px solid transparent')};

  &:hover {
    transform: scale(1.03); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
`;
