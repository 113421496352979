import { useState } from 'react';

import { Button, TextField, CircularProgress, Typography } from '@mui/material';
import { useResetPassword } from '../../../../../Context/ResetPassword/ResetPasswordContext';
import { apiClient } from '../../../../../Services/apiClient';
import MaskCPF from '../../../../../Shared/Functions/Mask/MaskCPF';

export function ValidateToken() {
  const { cpf, setToken, setStep } = useResetPassword();
  const [token, setTokenLocal] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const handleSubmit = async () => {
    setLoading(true);

    try {
      const response = await apiClient.post('/validate-token', { cpf, token });
      setToken(token)

      setStep(3);
    } catch (error) {
      setErrorMessage('Código inválido ou expirado!');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <TextField
        label="Seu CPF"
        value={MaskCPF(cpf)} 
        fullWidth
        disabled
      />
      <TextField
        label='Insira o código'
        value={token}
        onChange={(e) => setTokenLocal(e.target.value)}
        fullWidth
        margin='normal'
        focused
      />
      <Typography variant='body1' color='var(--color-GreyText)' textAlign='center' mb={3} mt={2} fontSize={13}>
        - Um código foi enviado para seu e-mail, verifique e informe, para validar seu usuário.
      </Typography>
      <Typography variant='body1' color='var(--color-GreyText)' textAlign='center' mb={3} mt={2} fontSize={13}>
        - Esse código tem validade de 15 minutos.
      </Typography>
      {errorMessage && <Typography color='error'>{errorMessage}</Typography>}
      <Button onClick={handleSubmit} variant='contained' fullWidth disabled={loading}>
        {loading ? <CircularProgress size={24} /> : "Validar Código"}
      </Button>
    </div>
  );
};