import SuccessMessage from './Components/SucessMessage/SecessMessage';


export function SuccessReset() {

  return (
    <>
      <SuccessMessage/>
    </>
  );
};
