import { useState } from 'react';
import { FormData } from '../../@types/types';
import { SelectChangeEvent } from '@mui/material';

export const useVehicleForm = (onSubmit: (data: FormData) => void) => {
  const [formData, setFormData] = useState<FormData>({
    title: '',
    description: '',
    mileage: '',
    displacement: '',
    acceptsExchange: false,
    price: 0,
    year: '',
    color: '',
    status: false,
    brand: '',
    type: ''
  });

  const [isLoading, setIsLoading] = useState(false); 

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | SelectChangeEvent
  ) => {
    const { name, value, type } = e.target as HTMLInputElement & { value: string | number };
    
    if (!name) {
      console.error("O campo 'name' não está definido no evento.");
      return;
    }
  
    if (type === 'checkbox') {
      // Para o tipo checkbox, mantemos a lógica original
      const newValue = (e.target as HTMLInputElement).checked;
      setFormData((prevData: FormData) => ({
        ...prevData,
        [name]: newValue,
      }));
    } else if (name === 'price') {
      // Para o campo 'price', manter a lógica de numérico
      const numericValue = value.replace(/\D/g, ''); // Remove caracteres não numéricos
      setFormData((prevData: FormData) => ({
        ...prevData,
        [name]: numericValue ? parseFloat(numericValue) : 0,  // Converte para número ou 0 se vazio
      }));
    } else if (['mileage', 'displacement', 'year'].includes(name)) {
      // Para 'mileage', 'displacement', 'year', tratamos como string
      setFormData((prevData: FormData) => ({
        ...prevData,
        [name]: value,  // Aqui, value já está como string
      }));
    } else {
      // Para outros campos, mantemos o valor como string
      setFormData((prevData: FormData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };  
  
  
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true); // Ativar loading
    console.log(formData)
    try {
      await onSubmit(formData); // Esperar a submissão
    } catch (error) {
      console.error("Erro ao enviar os dados:", error); // Tratar erro, se necessário
    } finally {
      setIsLoading(false); // Desativar loading
    }
  };

  return { formData, handleChange, handleSubmit, setFormData, isLoading }; // Retornar setFormData
};
